import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../../../environments/environment";
import {GlobalUserService} from "../../_helpers/globalUser/globalUser.service";
import {Location} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
	selector: 'app-cookie',
	templateUrl: './cookie.component.html',
	styleUrls: ['./cookie.component.scss']
})
export class CookieComponent implements OnInit {
	private selectedLanguage: string;
	isHasCookieApproval: boolean;
	constructor(
		private translateService: TranslateService
	) {
		let lang = window.navigator.language
		lang = lang.split('-')[0];
		let userLng = localStorage.getItem('lng');
		userLng = userLng ? JSON.parse(userLng) : '';

		this.selectedLanguage = userLng || lang || environment.defaultLocale;
		translateService.setDefaultLang(this.selectedLanguage);
		translateService.use(this.selectedLanguage);
		localStorage.setItem('lng', JSON.stringify(this.selectedLanguage));
		this.isHasCookieApproval = !!localStorage.getItem('isHasCookieApproval');
	}
	ngOnInit(): void {
	}
	cookieApproval() {
		localStorage.setItem('isHasCookieApproval', 'true');
		this.isHasCookieApproval = true;
	}
}
