import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {EventModel} from "./event.model";
import {BehaviorSubject, Observable} from "rxjs";
import {PaginationService} from "../pagination/pagination.service";
import {GlobalUserService} from "../globalUser/globalUser.service";
import {environment} from "../../../environments/environment";

@Injectable(
	{
		providedIn: 'root'
	}
)
export class EventService {
	private currentEventSubject: BehaviorSubject<EventModel>;
	defaultLng = environment.defaultLocale;
	selectedLng;
	constructor(
		private http: HttpClient,
		private paginationService: PaginationService,
		private globalUserService: GlobalUserService
	) {
		this.currentEventSubject = new BehaviorSubject(new EventModel());
	};

	// event
	public get currentEventSubjectValue(): EventModel {
		return this.currentEventSubject.value;
	}

	public get eventURL() {
		if(this.currentEventSubjectValue) {
			return '/'+this.currentEventSubjectValue.game.url+'/'+this.currentEventSubjectValue.slug
		} else {
			return;
		}

	}

	public setCurrentEventSubjectValue(event) {
		this.currentEventSubject.next(event);
		console.warn('this.currentEventSubjectValue', this.currentEventSubjectValue)
	}

	public currentEventSubjectSubscribe(): Observable<EventModel> {
		return this.currentEventSubject;
	}

	public saveEventInfo(data) {
		console.log('saveEventInfo data', data)
		const event = new EventModel(data)
		this.setCurrentEventSubjectValue(event);
	}

	public getAll(config?): any {
		const headers = new HttpHeaders({
			'Cache-Control': 'no-cache, no-store, must-revalidate'
		})
		let request = this.paginationService.makeQuery(config)
		let api = `/api/event/all`
		if(request) {
			api +=request
		}
		return this.http.get(api, {headers})
	}

	public getBySlug(eventSlug): any {
		const headers = new HttpHeaders({
			'Cache-Control': 'no-cache, no-store, must-revalidate'
		})
		let api = `/api/event/${eventSlug}`;
		return this.http.get(api, {headers})
	}
	public getConnectedData(eventId): any {
		const headers = new HttpHeaders({
			'Cache-Control': 'no-cache, no-store, must-revalidate'
		})
		let api = `/api/event/connectedData/${eventId}`;
		return this.http.get(api, {headers})
	}

	public create(event, militarySpecialitiesWithLevels): any {
		const headers = new HttpHeaders({
			'Cache-Control': 'no-cache, no-store, must-revalidate'
		})
		let api = `/api/event`;
		return this.http.post(api, {event, militarySpecialitiesWithLevels}, {headers})
	}

	public update(event, militarySpecialitiesForUpdate, militarySpecialitiesForCreate): any {
		const headers = new HttpHeaders({
			'Cache-Control': 'no-cache, no-store, must-revalidate'
		})
		let api = `/api/event/update`;
		return this.http.put(api, {event, militarySpecialitiesForUpdate, militarySpecialitiesForCreate}, {headers})
	}

	public checkSlug(slug) {
		const headers = new HttpHeaders({
			'Cache-Control': 'no-cache, no-store, must-revalidate'
		})
		let api = `/api/event/check/${slug}`;
		return this.http.get(api,  {headers})
	}

	public getFieldTranslate(event, field) {
		let selectedLng;
		if(this.globalUserService.currentUserObjectValue && this.globalUserService.currentUserObjectValue.lng) {
			selectedLng = this.globalUserService.currentUserObjectValue.lng
		}
		return event[selectedLng] ? event[selectedLng][field]: event[this.defaultLng][field]
	}
}
