import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-modal-sure',
	templateUrl: './modal-sure.component.html',
	styleUrls: ['./modal-sure.component.css']
})
export class ModalSureComponent implements OnInit {
	@Input() title;
	@Input() message;

	constructor(public activeModal: NgbActiveModal
	) {
	}

	ngOnInit(): void {
	}

	loadReport() {
		this.activeModal.close('yes');
	}

}
