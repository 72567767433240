import {Component, Injectable, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../../../environments/environment";
import {GlobalUserService} from "../../_helpers/globalUser/globalUser.service";
import {Location} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {NotificationService} from "../../_helpers/notification/notification.service";
import {NotificationModel} from "../../_helpers/notification/notification.model";
import {Observable} from "rxjs";
import {take} from "rxjs/operators";
import {LngService} from "../../_helpers/lng/lng.service";

@Component({
	selector: 'app-notification-container',
	templateUrl: './notification-container.component.html',
	styleUrls: ['./notification-container.component.scss']
})
export class NotificationContainerComponent implements OnInit {
	notification: NotificationModel;

	notifications:  NotificationModel[];
	mainClass;
	selectedLanguage: string;
	languages: { id: string, title: string }[] = [];
	constructor(
		private translateService: TranslateService,
		private globalUserService: GlobalUserService,
		private location: Location,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		public notificationService: NotificationService,
		public lngService: LngService
	) {	}

	ngOnInit(): void {
		this.notificationService.getActiveNotifications().pipe(take(1)).subscribe((res: any) => {
			console.log('getActiveNotifications res', res);
			this.notifications = res.notifications;
		})
		// this.notificationService.newNotification()
		// 	.subscribe(res => {
		// 		console.log('notification.getMessage!!!!!!!!!!!!!', res);
		// 		this.notification = res;
		// 		this.notifications.push(res);
		// 	});
		// this.notificationService.notificationUpdate()
		// 	.subscribe(res => {
		// 		console.log('notification.notificationUpdate!!!!!!!!!!!!!', res);
		// 		let isFind = false;
		// 		this.notifications = this.notifications.map(n => {
		// 			if(n._id === res._id) {
		// 				isFind = true;
		// 				console.log('isFind', isFind);
		// 				return res;
		// 			} else {
		// 				return n;
		// 			}
		// 		})
		// 		if(!isFind) {
		// 			this.notifications.push(res);
		// 		}
		// 		// this.notification = res;
		// 		// this.notifications.push(res);
		// 	});
	}

	closeNotification(notifiId) {
		this.notifications = this.notifications.filter(function(item) {
			return item._id !==notifiId
		});
	}
}
