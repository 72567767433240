<div class="modal-header">
	<h4 class="modal-title">Create episode</h4>
	<button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">
	<div>
		<ngb-alert *ngIf="this.errorMessage" [dismissible]="true" class="alert alert-danger" (closed)="closeAlert()">
			{{this.errorMessage}}
		</ngb-alert>
		<div *ngFor="let lng of alreadyAddedLngs">
			<div class="row">
				<div class="form-group col-6">
					<label>Choose language</label>
					<small *ngIf="lng == defaultLng"> Нельзя поменять, потому что это язык события</small>
					<select class="form-control" [disabled]="lng == defaultLng"
							(change)="changeLng($event.target.value, lng)"
					>
						<option [selected]="lng === ''">- выберите язык / choose language -</option>
						<option *ngFor="let item of lngs" [selected]="lng === item.lng" [value]="item.lng" [id]="item.id" [disabled]="alreadyAddedLngs.indexOf(item.lng) !=-1">{{item.text}}</option>
					</select>
				</div>
			</div>
			<div *ngIf="newEpisode[lng]">
				<div class="form-group md-3">
					<label for="exampleFormControlInput1">Episode name</label>
					<input #name="ngModel" name="name" type="text" class="form-control" id="exampleFormControlInput1"
						   [(ngModel)]="newEpisode[lng].name" required/>
					<div *ngIf="name.invalid" class="alert alert-danger">
						<div *ngIf="name.errors.required">
							field is required
						</div>
					</div>
				</div>
				<div class="form-group md-3">
					<label for="exampleFormControlInput2">Episode file link</label>
					<input #fileLink="ngModel" name="fileLink" type="text" class="form-control" id="exampleFormControlInput2"
						   [(ngModel)]="newEpisode[lng].fileLink"
						   pattern="{{fileLinkRegexp}}" required>
					<div *ngIf="fileLink.invalid" class="alert alert-danger">
						<div *ngIf="fileLink.errors.required">
							field is required
						</div>
						<div *ngIf="fileLink.errors.pattern">
							field is invalid
						</div>
					</div>
				</div>
			</div>
			<button class="btn btn-primary" *ngIf="alreadyAddedLngs.length > 1 && lng !== defaultLng" (click)="deleteTranslate(lng)">Remove translation</button>
		</div>

		<button class="btn btn-primary" *ngIf="alreadyAddedLngs.indexOf('') ==-1" (click)="addTranslate()">Add translation</button>

		<form class="form-inline">
			<div class="form-group hidden">
				<input name="datepicker"
				       class="form-control"
				       ngbDatepicker
                       #datepicker="ngbDatepicker"
				       [autoClose]="'outside'"
				       (dateSelect)="onDateSelection($event)"
				       [displayMonths]="2"
				       [dayTemplate]="t"
				       outsideDays="hidden"
				       [startDate]="fromDate"
				       tabindex="-1">
				<ng-template #t let-date let-focused="focused">
        <span class="custom-day"
              [class.focused]="focused"
              [class.range]="isRange(date)"
              [class.faded]="isHovered(date) || isInside(date)"
              (mouseenter)="hoveredDate = date"
              (mouseleave)="hoveredDate = null">
          {{ date.day }}
        </span>
				</ng-template>
			</div>
			<div class="form-group">
				<div class="input-group">
					<input #dpFromDate
					       class="form-control" placeholder="yyyy-mm-dd"
					       name="dpFromDate"
					       required
					       [value]="formatter.format(fromDate)"
					       aria-describedby="button-addon2"
					       (input)="fromDate = validateInput(fromDate, dpFromDate.value)">
					<button class="btn btn-highlight" id="button-addon2"
					        (click)="datepicker.toggle()" type="button">
						<i class="bi bi-calendar"></i>
					</button>
				</div>
			</div>
			<div class="form-group ml-2">
				<div class="input-group">
					<input #dpToDate
					       class="form-control" placeholder="yyyy-mm-dd"
					       name="dpToDate"
					       required
					       [value]="formatter.format(toDate)"
					       (input)="toDate = validateInput(toDate, dpToDate.value)">
					<div class="input-group-append">
						<button class="btn btn-highlight" (click)="datepicker.toggle()" type="button">
							<i class="bi bi-calendar"></i>
						</button>
					</div>
				</div>
			</div>
		</form>

		<button class="btn btn-highlight" (click)="saveNewEpisode()">Save</button>
	</div>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-primary" (click)="activeModal.close('Close click')">Close</button>
</div>
