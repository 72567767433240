import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from "../../../environments/environment";
import {GlobalUserService} from "../globalUser/globalUser.service";
import {EventService} from "../event/event.service";
import {ActivatedRoute} from "@angular/router";

@Injectable(
	{
		providedIn: 'root'
	}
)
export class LngService {
	defaultLng = environment.defaultLocale;
	infoLocales = environment.infoLocales;
	constructor(
		private http: HttpClient,
		private globalUserService: GlobalUserService,
		private eventService: EventService,
		private activatedRoute: ActivatedRoute
	) {};

	public getAll(): any {
		const headers = new HttpHeaders({
			'Cache-Control': 'no-cache, no-store, must-revalidate'
		})
		let api = `/api/lng/all`;
		return this.http.get(api, {headers})
	}

	public getFieldTranslate(item, field, defaultLocale?) {
		let selectedLng;
		let userSelectedLng
		if(defaultLocale && defaultLocale.lng) {
			selectedLng = defaultLocale.lng;
		}
		if(this.globalUserService.currentUserObjectValue && this.globalUserService.currentUserObjectValue.lng) {
			userSelectedLng = this.globalUserService.currentUserObjectValue.lng
		}
		if(item[userSelectedLng] && item[userSelectedLng][field]) {
			return item[userSelectedLng][field]
		} else {
			if(item[selectedLng] && item[selectedLng][field]) {
				return item[selectedLng][field]
			} else {
				let returnValue
				this.infoLocales.forEach(el => {
					if(item[el] && item[el][field]) {
						returnValue = item[el][field];
						return;
					}
				})
				return returnValue;
			}
		}
	}

	public get userSelectedLng() {
		let selectedLng;
		if(this.globalUserService.currentUserObjectValue && this.globalUserService.currentUserObjectValue.lng) {
			selectedLng = this.globalUserService.currentUserObjectValue.lng
		}
		return selectedLng || this.defaultLng;
	}
}
