export class EpisodeModel {
	constructor() {
		this.toDate = '';
		this.fromDate = '';
		this.isClose = false;
		this.ru = null;
		this.en = null;
		this.es = null;

	}

	//переводы
	public ru;
	public en;
	public es;

	public toDate;
	public fromDate;
	public isClose;
	public event;
}
