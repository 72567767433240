import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Faction} from './faction.model';
import {Observable} from "rxjs";

@Injectable()
export class FactionService {

	constructor(private http: HttpClient) {
	}

	loadAllFactions(eventId?): Observable<any> {
		const headers = new HttpHeaders({
			'Cache-Control': 'no-cache, no-store, must-revalidate'
		})
		return this.http.get('/api/faction/'+eventId, {headers: headers})
	}

	loadNewFactions(): Observable<any> {
		const headers = new HttpHeaders({
			'Cache-Control': 'no-cache, no-store, must-revalidate'
		})
		return this.http.get('/api/faction/load', {headers: headers})
	}


	getFactions(fromPage, eventId): Observable<any> {
		const headers = new HttpHeaders({
			'Cache-Control': 'no-cache, no-store, must-revalidate'
		})
		return this.http.get(`/api/faction/${fromPage}/${eventId}`, {headers: headers})
	}

	isAlreadyLoad(): Observable<any> {
		const headers = new HttpHeaders({
			'Cache-Control': 'no-cache, no-store, must-revalidate'
		})
		return this.http.get('/api/faction/isAlreadyLoad', {headers: headers})
	}

	getSectorials(parentId?): Observable<any> {
		const headers = new HttpHeaders({
			'Cache-Control': 'no-cache, no-store, must-revalidate'
		})
		return this.http.get('/api/faction/sectorials/' + parentId, {headers: headers})
	}

}
