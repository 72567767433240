import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {GlobalUserService} from "./globalUser/globalUser.service";
import {AuthService} from './auth/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	constructor(
		private authService: AuthService,
		private globalUserService: GlobalUserService
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(catchError(err => {
			if (err.status === 401) {
				let user = this.globalUserService.currentUserObjectValue;
				// auto logout if 401 response returned from api
				console.log('error')
				if(user && user.token) {
					this.authService.logout();
				}
			}

			const error = err.error || err.statusText;
			return throwError(error);
		}))
	}
}
