import {environment} from "../../../environments/environment";

let defaultLng = environment.defaultLocale;

export class EventModel {
	constructor(data?) {
		if(data && data._id) {
			this._id = data._id;
			this.ru = data.ru;
			this.en = data.en;
			this.es = data.es;

			this.isActive = data.isActive;
			this.type = data.type;
			this.game = data.game;
			this.slug = data.slug;
			this.status = data.status;
			this.news = data.news;
			this.episodes = data.episodes;
			this.logo = data.logo;

			this.reportImageCount = data.reportImageCount;
			this.reportTextLenght = data.reportTextLenght;
			this.maxRank = data.maxRank;
			this.canChangeSectorial = data.canChangeSectorial;
			this.canChangeFraction = data.canChangeFraction;
			this.pageSizeUser = data.pageSizeUser;
			this.pageSizeEvent = data.pageSizeEvent;
			this.pageSizeReport = data.pageSizeReport;
			this.canPlayMissionManyTimes = data.canPlayMissionManyTimes;
			this.sectorialsAsFaction = data.sectorialsAsFaction;
			this.defaultLocale = data.defaultLocale || {};
			this.specopsStat = data.specopsStat || {};
			this.factionAdd = data.factionAdd || {};
			this.factionDiscord = data.factionDiscord || {};

			this.ratingType = data.ratingType;

			this.personalRatingType = data.personalRatingType;
			this.factionRatingType = data.factionRatingType;
			this.ratingByLocation = data.ratingByLocation;
			this.imageBucket = data.imageBucket;
			this.maxVP = data.maxVP;
			this.militarySpec = data.militarySpec;

		} else {
			this.ru = null;
			this.en = null;
			this.es = null;
			this.isActive = false;
			this.type = 'event';
			this.game = 'infinity';
			this.status = '';
			this.reportImageCount = 3;
			this.reportTextLenght = 1500;
			this.maxRank = 5;
			this.canChangeSectorial = false;
			this.canChangeFraction = true;
			this.pageSizeUser = 10;
			this.pageSizeEvent = 10;
			this.pageSizeReport = 10;
			this.maxVP = 450;
			this.specopsStat = {};
			this.ratingByLocation = false;
			this.ratingType = 'v1';
			this.personalRatingType = 'v1';
			this.factionRatingType = 'v1';
			this.militarySpec = {
				mobile_reserves: '',
				supply: '',
				support: '',
				psychological_preparation: '',
				rapid_reaction: '',
				espionage: '',
			};
		}
	}

	public _id;

	//переводы
	public ru;
	public en;
	public es;

	public img;
	public type;
	public game;
	public isActive;
	public status;
	public slug;
	public news;
	public episodes;
	public logo;

	public specopsStat;
	public maxVP;
	public defaultLocale;
	public ratingType;
	public personalRatingType;
	public factionRatingType;
	public reportImageCount;
	public reportTextLenght;
	public canChangeSectorial;
	public canChangeFraction;
	public pageSizeUser;
	public pageSizeEvent;
	public pageSizeReport;
	public maxRank;
	public sectorialsAsFaction;
	public factionAdd;
	public factionDiscord;
	public canPlayMissionManyTimes;
	public ratingByLocation;
	public imageBucket;
	public deleteTranslate;
	public militarySpec;
}
