import {Injectable} from '@angular/core';
import {GlobalUserModel} from '../globalUser/globalUser.model';
import {GlobalUserService} from "../globalUser/globalUser.service";
import {UserModel} from '../user/user.model';
import {UserService} from '../user/user.service';
import {HelpersService} from '../helpers.service';
import {Observable, BehaviorSubject, throwError, Subject} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from "../../../environments/environment";
import {TranslateService} from "@ngx-translate/core";
// import {ChatService} from "../chat/chat.service";

@Injectable({
	providedIn: 'root'
})

export class AuthService {
	headers = new HttpHeaders().set('Content-Type', 'application/json');
	// private currentUserObjectSubject: BehaviorSubject<GlobalUserModel>;

	private currentUserSubject: BehaviorSubject<String>;
	public currentUser: Observable<String>;
	public currentUserRole: String;
	public isShowBanner: BehaviorSubject<Boolean>;

	constructor(
		public router: Router,
		private translateService: TranslateService,
		private http: HttpClient,
		private globalUserService: GlobalUserService,
		private userService: UserService,
		private helpersService: HelpersService,
		// private chatService: ChatService
	) {
		this.isShowBanner = new BehaviorSubject(false);
	}

	//isShowBanner
	public get isShowBannerGet(): Boolean {
		return this.isShowBanner.value;
	}

	public setIsShowBanner(nickname) {
		this.isShowBanner.next(nickname);
	}

	public isShowBannerSubscribe(): Observable<Boolean> {
		return this.isShowBanner;
	}

	public saveUserInfo(data) {
	    const nickname = data.nickname;
	    const isActive = data.isActive;
	    console.log('isActive', isActive);
	    const user = new GlobalUserModel(data)
	    this.globalUserService.setCurrentUserObjectValue(user);
	    localStorage.setItem('currentUser', JSON.stringify(nickname));
		console.log('saveUserInfo user.lng', user.lng);
		let selectedLanguage = user.lng || environment.defaultLocale;
		console.log('selectedLanguage', selectedLanguage);
	    localStorage.setItem('lng', JSON.stringify(selectedLanguage));
		this.translateService.use(selectedLanguage);
	    // this.currentUserSubject.next(nickname);
	    localStorage.setItem('access_token', data.sessionID)
	    // localStorage.setItem('role', user.role)
	    this.currentUserRole = data.role;
	  }

	public checkServerSession(): Promise<any> {
		return new Promise((resolve, reject) => {
			return this.http.get('/api/globalUser/isLogin').subscribe((res: any) => {
				console.log('checkServerSession', res);
                    this.saveUserInfo(res);
					resolve(true);
				},
				err => {
					localStorage.removeItem('access_token')
				resolve(false)
				}); // TODO: fix me!(( https://trello.com/c/Jm447G7H/152-%D1%8F-%D0%BD%D0%B5-iscurrent
		});
	}

	public checkEmail(code): Observable<any> {
	    const headers = new HttpHeaders({
	      'Cache-Control': 'no-cache, no-store, must-revalidate'
	    })
	    return this.http.get(`/api/auth/checkPassword/${code}`, {headers: headers})
	}

	public resendSignUp(email): Observable<any> {
		const headers = new HttpHeaders({
		  'Cache-Control': 'no-cache, no-store, must-revalidate'
		})
		return this.http.get(`/api/auth/resendSignUp/${email}`, {headers: headers})
	}

	public passwordRecovery(email): Observable<any> {
		const headers = new HttpHeaders({
		  'Cache-Control': 'no-cache, no-store, must-revalidate'
		})
		return this.http.get(`/api/auth/passwordRecovery/${email}`, {headers: headers})
	}


	public updatePassword(newPassword, confirmPassword, passwordRecoveryGuid): Observable<any> {
		const headers = new HttpHeaders({
		  'Cache-Control': 'no-cache, no-store, must-revalidate'
		})
		return this.http.post(`/api/auth/passwordRecovery`, {newPassword, confirmPassword, passwordRecoveryGuid},{headers: headers})
	}

	// используем для проверки прав внутри события
	get isAdmin(): boolean {
		let eventUser = this.userService.currentUserObjectValue;
		let eventUserRole;
		if(eventUser) {
			eventUserRole = eventUser.role
		}
		let role = this.globalUserService.currentUserObjectValue ? this.globalUserService.currentUserObjectValue.role : 'user';
		return (eventUserRole == 'admin' || role == 'superadmin') ? true : false;
	}

	//используем в шиве для проверки прав глобального пользователя
	get isSuperAdmin(): boolean {
		let role = this.globalUserService.currentUserObjectValue && this.globalUserService.currentUserObjectValue.role;
		//todo: get role not in LS
		return (role == 'superadmin') ? true : false;
	}

	get isActive(): boolean {
		let user = this.globalUserService.currentUserObjectValue;
		//todo: get role not in LS
		return user.isActive;
	}

	logout() {
		// remove user from local storage and set current user to null
		localStorage.removeItem('currentUser');
		localStorage.removeItem('access_token');
		this.globalUserService.setCurrentUserObjectValue(null);
		this.http.get('/api/auth/logout').subscribe((res: any) => {
			console.log('auth navigate');
			this.router.navigate(['/'])
		})
	}

	// Sign-up
	signUp(user: GlobalUserModel): Observable<any> {
		let api = `/api/auth/signup`;
		return this.http.post(api, {user})
	}

	// Sign-in
	signIn(user: GlobalUserModel) {
		// this.chatService.sendMessage('login')
		return this.http.post<any>(`/api/auth/login`, user)
	}

	signInVK() {
		window.open('/api/auth/vkontakte', "mywindow", "location=1,status=1,scrollbars=1, width=800,height=800");
		let listener = window.addEventListener('message', (message) => {
			if (message && message.data && message.data.nickname) {
				console.log('message.data', message.data)
        this.saveUserInfo(message.data)
				// return user;
				//todo: save role not in LS
				this.router.navigate(['/profile/' + message.data.nickname]);
			}
		});
	}

	doLogout() {
		let api = `/api/auth/logout`;
		this.http.get(api)
			.subscribe(value => {
				},
				error => {
					this.helpersService.handleError(error);
					// error - объект ошибки
				});
		let removeToken = localStorage.removeItem('access_token');
		if (removeToken == null) {
			this.router.navigate(['sign-in']);
		}
	}

	// User profile
	getUserProfile(nickname): any {
		let api = `/api/user/${nickname}`;
		const header = this.headers.append('Authorization', `Bearer ${this.helpersService.getToken()}`);

		return this.http.get(api, {headers: header})
			.pipe(
				map((res: Response) => {
					console.log('getUserProfile res, ', res)
					return res || {}
				}),
				catchError(this.helpersService.handleError)
			)
	}

}
