export class GlobalUserModel {
	constructor(data?) {
		let user;
		if (data && data.user) {
			user = data.user
		}
		if (data && data.nickname) {
	        user = data
	    }
	    if (user && user.nickname) {
			this._id = user._id;
			this.email = user.email;
			this.nickname = user.nickname;
			this.avatar = user.avatar;
			this.isActive = user.isActive;
			this.contactLink = user.contactLink;
			this.achievements = user.achievements;
			this.token = user.token;
			this.isCurrent = user.isCurrent;
			this.role = user.role;
			this.events = user.events;
			this.lng = user.lng;
	    } else {
			this.email = '';
			this.password = '';
			this.confirmPassword = '';
			this.isActive = '';
			this.contactLink = '';
			this.token = '';
			this.achievements = [];
			this.isCurrent = false;
	    }
	}

	public _id;
	public email;
	public password;
	public confirmPassword;
	public contactLink;
	public achievements;
	public isActive;
	public token;
	public isCurrent;
	public activatedGuid;
	public nickname;
	public nicknameKey;
	public avatar;
	public role;
	public events;
	public lng;
}
