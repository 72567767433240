import {CommonModule} from "@angular/common";
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {ImageCropperModule} from 'ngx-image-cropper';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {MissingTranslationHandler, TranslateLoader, TranslateModule} from "@ngx-translate/core";

import {EditEpisodeComponent} from '../admin/episode/modal-edit-episode/edit-episode.component';
import {ModalSureComponent} from '../modal/modal-sure/modal-sure.component';
import {ModalInfoComponent} from '../modal/modal-info/modal-info.component';
import {ModalReportResultComponent} from '../modal/modal-report-result/modal-report-result.component';
import {ModalErrorComponent} from '../modal/modal-error/modal-error.component';
import {ModalPhotoCropComponent} from '../modal/modal-photo-crop/modal-photo-crop.component';
import {ModalAchievementComponent} from '../modal/modal-achievement/modal-achievement.component';

import {ChooseLngComponent} from './choose-lng/choose-lng.component';
import {NotificationComponent} from "./notification/notification.component";
import {NotificationContainerComponent} from "./notification-container/notification-container.component";
import {PaginationComponent} from "../_helpers/pagination/pagination.component";
import {MissingTranslationService} from "../_helpers/translate/missing-translation.service";
import {SearchFilterPipe} from '../_helpers/search-filtered/search-filter.pipe';
import {SearchUserFilterPipe} from "../_helpers/searchUser-filtered/search-filter.pipe";
// import {ChatService} from "../_helpers/chat/chat.service";
import {NotificationService} from "../_helpers/notification/notification.service";
import {NgxPaginationModule} from 'ngx-pagination';
import {CookieComponent} from "./cookie/cookie.component";

// import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

// import {environment} from '../../environments/environment';
// console.log('environment.socketURL', environment.socketURL);
// const config: SocketIoConfig = { url: environment.socketURL, options: {reconnection: false} };


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateLoader {
	return new TranslateHttpLoader(http, '../assets/locale/', '.json');
}

@NgModule({
	declarations: [
		EditEpisodeComponent,
		ModalSureComponent,
		ModalInfoComponent,
		ModalReportResultComponent,
		ModalErrorComponent,
		ModalPhotoCropComponent,
		ModalAchievementComponent,
		ChooseLngComponent,
		NotificationComponent,
		NotificationContainerComponent,
		PaginationComponent,
		CookieComponent,
		SearchFilterPipe,
		SearchUserFilterPipe
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		ImageCropperModule,
		NgbModule,
		HttpClientModule,
		NgxPaginationModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			},
			missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MissingTranslationService },
		}),
		// SocketIoModule.forRoot(config)
	],
	exports: [
		ChooseLngComponent,
		NotificationComponent,
		PaginationComponent,
		SearchFilterPipe,
		SearchUserFilterPipe,
		NgxPaginationModule,
		NotificationContainerComponent,
		TranslateModule,
		CookieComponent
	],
	providers: [
		NotificationService
	],
})
export class ShareModule {
}
