import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {BehaviorSubject, Observable} from "rxjs";
import {GlobalUserModel} from "./globalUser.model";
import {PaginationService} from "../pagination/pagination.service";

@Injectable(
	{
		providedIn: 'root'
	}
)
export class GlobalUserService {
	private currentUserObjectSubject: BehaviorSubject<GlobalUserModel>;

	constructor(
		private http: HttpClient,
		private paginationService: PaginationService
	) {
		this.currentUserObjectSubject = new BehaviorSubject(new GlobalUserModel());
	};

	// user
	public get currentUserObjectValue(): GlobalUserModel {
		return this.currentUserObjectSubject.value;
	}

	public setCurrentUserObjectValue(user) {
		console.log('update globaluUser', user)
		this.currentUserObjectSubject.next(user);
	}

	public currentUserValueObjectSubscribe(): Observable<GlobalUserModel> {
		return this.currentUserObjectSubject;
	}

	public updateUser(user, needCreateSpecops): any {
		const headers = new HttpHeaders({
			'Cache-Control': 'no-cache, no-store, must-revalidate'
		})
		let api = `/api/globalUser`;
		return this.http.put(api, {user, needCreateSpecops}, {headers})
	}

	public updateLng(lng): any {
		const headers = new HttpHeaders({
			'Cache-Control': 'no-cache, no-store, must-revalidate'
		})
		let api = `/api/globalUser/lng`;
		return this.http.put(api, {lng}, {headers})
	}

	public updateAvatar(avatar): any {
		const headers = new HttpHeaders({
			'Cache-Control': 'no-cache, no-store, must-revalidate'
		})
		let api = `/api/globalUser/avatar`;
		return this.http.put(api, {avatar}, {headers})
	}

	public getByNickname(nickname): any {
		let api = `/api/globalUser/${nickname}`;
		return this.http.get(api)
	}

	public getAll(): any {
		let api = `/api/globalUser/all`;
		return this.http.get(api)
	}

	public getUsersOnPage(config): any {
		let request = this.paginationService.makeQuery(config);
		let api = `/api/globalUser/onPage${request}`
		return this.http.get(api)
	}

	isConnectToEvent(eventId): boolean {
		return this.currentUserObjectValue?.events.includes(eventId)
	}

}
