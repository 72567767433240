// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	locales: ['en', 'ru'],
	infoLocales: ['en', 'ru', 'esp'],
	defaultLocale: 'ru',
	specopsStatus: {
		0:'specopsdie',
		1:'specopslive',
		2:'specopsNouse',
		3:'specopsEva'
	}
	, achivType: ['global', 'event', 'system']

	,report: {
		imageCount: 3,
		textLength: 1500
	},
	canChangeSectorial: false,
	//a. true - пользователь может менять секториалы внутри одной фракции как захочет.
	//    b. false - должен выбрать один секториал и играть именно им весь эпизод
	canChangeFraction: true,
	//    a. true - наемник может менять фракцию между миссиями
	//    b. false - наемник должен играть всю кампанию за одну фракцию
	specopsStatChange: {
		cc: {
			0: {plus: 0, sumPlus: 0, exp: 0},
			1: {plus: 2, sumPlus: 2, exp: 2},
			2: {plus: 3, sumPlus: 5, exp: 3},
			3: {plus: 5, sumPlus: 10, exp: 5}
		},
		bs: {
			0: {plus: 0, sumPlus: 0, exp: 0},
			1: {plus: 1, sumPlus: 1, exp: 2},
			2: {plus: 1, sumPlus: 2, exp: 3},
			3: {plus: 1, sumPlus: 3, exp: 5}
		},
		ph: {
			0: {plus: 0, sumPlus: 0, exp: 0},
			1: {plus: 1, sumPlus: 1, exp: 2},
			2: {plus: 2, sumPlus: 3, exp: 3},
			max: 14
		},
		wip: {
			0: {plus: 0, sumPlus: 0, exp: 0},
			1: {plus: 1, sumPlus: 1, exp: 2},
			2: {plus: 2, sumPlus: 3, exp: 3},
			3: {plus: 3, sumPlus: 6, exp: 5},
			max: 15
		},
		arm: {
			0: {plus: 0, sumPlus: 0, exp: 0},
			1: {plus: 0, sumPlus: 0, exp: 0},
			2: {plus: 1, sumPlus: 1, exp: 5},
			3: {plus: 2, sumPlus: 3, exp: 5},
		},
		bts: {
			0: {plus: 0, sumPlus: 0, exp: 0},
			1: {plus: 3, sumPlus: 3, exp: 2},
			2: {plus: 3, sumPlus: 6, exp: 3},
			3: {plus: 3, sumPlus: 9, exp: 5},
			max: 6
		},
		w: {
			0: {plus: 0, sumPlus: 0, exp: 0},
			1: {plus: 0, sumPlus: 0, exp: 0},
			2: {plus: 0, sumPlus: 0, exp: 0},
			3: {plus: 1, sumPlus: 1, exp: 10, value: 2},
			max: 2
		}
	},
	// slug: { свойство(bts):{max:9}}
	differentSpecopsStatChange: {},
	chats: {
		101: '830760333366394921',
		201: '830755554539339776',
		301: '830754900581023775',
		401: '809808418599993364',
		501: '830755975589527573',
		601: '830756056929271848',
		701: '830756156716351528',
		801: '830756233128575026',
		903: '830756317986947073',
		1001: '830756369782013962'
	},
	pageSize: {
		user: 10,
		event: 2,
		report: 10
	}
	//903 - Japanese Secessionist Army
	, sectorialsAsFaction: [903]
	, maxRank: 5
	,ratingTypes: {
		'infinity': [
			{name: 'то, что сейчас есть', description: 'подсчет рейтинга, как описано в DLICS 3.0', lng: 'ru', version: 'v1'},
			{name: 'новый от 22.05.2023', description: '7 за победу, 6 за ничью, 5 за поражение', lng: 'ru', version: 'v2'}
		],
		'infinity2': [
			{name: 'для другой игры', description: 'какое то описание', lng: 'ru', game: 'infinity2', version: 'v1'}
		]
	}
	,personalRatingTypes: {
		'infinity': [
			{name: 'старый', description: '', lng: 'ru', version: 'v1'},
			{name: 'новый', description: '', lng: 'ru', version: 'v2'}
		],
		'infinity2': [
			{name: 'для другой игры', description: 'какое то описание', lng: 'ru', game: 'infinity2', version: 'v1'}
		]
	}
	,fanctionRatingTypes: {
		'infinity': [
			{name: 'старый', description: 'подсчет рейтинга, как описано в DLICS 3.0', lng: 'ru', version: 'v1'},
			{name: 'новый от 22.05.2023', description: '', lng: 'ru', version: 'v2'}
		],
		'infinity2': [
			{name: 'для другой игры', description: 'какое то описание', lng: 'ru', game: 'infinity2', version: 'v1'}
		]
	}
	, notificationTypes: ['primary', 'warning', 'error']
	, socketURL: 'ws://localhost:4200'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
