import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../../../environments/environment";
import {GlobalUserService} from "../../_helpers/globalUser/globalUser.service";
import {Location} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
	selector: 'app-choose-lng',
	templateUrl: './choose-lng.component.html',
	styleUrls: ['./choose-lng.component.scss']
})
export class ChooseLngComponent implements OnInit {
	constructor(
		private translateService: TranslateService,
		private globalUserService: GlobalUserService,
		private location: Location,
		private router: Router,
		private activatedRoute: ActivatedRoute
	) {
	}

	mainClass;
	selectedLanguage: string;
	languages: { id: string, title: string }[] = [];

	ngOnInit(): void {

		let lang = window.navigator.language
		lang = lang.split('-')[0];
		let userLng = localStorage.getItem('lng');
		userLng = userLng ? JSON.parse(userLng) : '';

		let selectedLng = userLng || lang || environment.defaultLocale;

		this.translateService.use(selectedLng);
		this.selectedLanguage = selectedLng;
		// localStorage.setItem('lng', JSON.stringify(this.selectedLanguage));
		this.getLanguageList();
	}

	getLanguageList() {
		this.translateService.get(environment.locales.map(x => `LANGUAGES.${x.toUpperCase()}`))
			.subscribe(translations => {
				// init dropdown list with TRANSLATED list of languages from config
				this.languages = environment.locales.map(x => {
					return {
						id: x,
						title: translations[`LANGUAGES.${x.toUpperCase()}`],
					};
				});
			});
	}

	changeLocale(lngId) {
		this.selectedLanguage = lngId;
		this.translateService.use(this.selectedLanguage);
		this.translateService.setDefaultLang(this.selectedLanguage);
		localStorage.setItem('lng', JSON.stringify(this.selectedLanguage));
		this.globalUserService.updateLng(this.selectedLanguage)
			.subscribe(res => {
				this.getLanguageList();
				let globalUser = this.globalUserService.currentUserObjectValue;
				globalUser.lng = this.selectedLanguage;
				this.globalUserService.setCurrentUserObjectValue(globalUser);
				let currentUrl = this.router.url;
				this.router.routeReuseStrategy.shouldReuseRoute = () => false;
				this.router.onSameUrlNavigation = 'reload';
				this.router.navigate([currentUrl]);
				// //TODO: перезагрузка останется но на сервере будем следить за языком и отдавать только нужный перевод
			})
	}
}
