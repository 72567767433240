import {Routes, RouterModule} from '@angular/router';

import {PageNotFoundComponent} from './infinity/page-not-found/page-not-found.component';


const routes: Routes = [

	// {path: '404', component: PageNotFoundComponent},
	{path: 'infinity/:eventSlug', loadChildren: () => import('./infinity/infinity.module').then(m => m.InfinityModule), data: { bodyClass: 'infinity' } },
	{path: 'sw-legion/:eventSlug', loadChildren: () => import('./sw-legion/infinity.module').then(m => m.InfinityModule), data: { bodyClass: 'infinity' } },
	{path: '', loadChildren: () => import('./shiva/shiva.module').then(m => m.ShivaModule), data: { bodyClass: 'shiva' } },

	// otherwise redirect to home
	// {path: '**', redirectTo: '404'}
];

export const appRoutingModule = RouterModule.forRoot(routes, {
	paramsInheritanceStrategy: 'always' // THIS PARAM
});
