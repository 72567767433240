import {Component, Input, Output, OnInit} from '@angular/core';
import {EventEmitter} from "@angular/core";
import {PaginationService} from "./pagination.service";

@Component({
	selector: 'pagination',
	templateUrl: './pagination.component.html',
	styleUrls: []
})
export class PaginationComponent implements OnInit {
	@Input() itemType;
	@Input() sort;
	@Input() query;
	@Input() callbackAction;

	loading: Boolean;
	constructor(
		public paginationService: PaginationService
	) {}

	ngOnInit(): void {
		// this.paginationService.init(this.itemType);
		// this.loadPage(0);
	}

	// loadPage(pageNumber) {
	// 	let result = this.paginationService.setPage({pageNumber})
	// 	if(result && result.message && this.paginationService.page.totalPages) {
	// 		return;
	// 	}
	// 	this.loading = true;
	// 	console.log('this.query', this.query);
	// 	// let request = this.paginationService.makeQuery(this.sort, this.query);
	// 	// this.callbackAction(request)
	// }
	//
	// setPage(page) {
	// 	// this.paginationService.setPage(page)
	// }

}
