import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import {catchError, map} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {NewsModel} from "../news/news.model";
import {Observable} from "rxjs";
import {NotificationModel} from "./notification.model";

@Injectable()
export class NotificationService {
	constructor(
		private http: HttpClient,
		// private socket: Socket
	) {}

	// currentNotification = this.socket.fromEvent<NotificationModel>('notification');
	// notifications = this.socket.fromEvent<string[]>('notifications');

	sendNotification(msg: string) {
		// this.socket.emit('notification', msg);
	}
	newNotification() {
		// return this.socket.fromEvent('notification').pipe(map((data: any) => {
		// 	console.log('notification', data);
		// 	return data
		// }));
		return [];
	}

	notificationUpdate() {
		return [];
		// return this.socket.fromEvent('notificationUpdate').pipe(map((data: any) => {
		// 	console.log('notification', data);
		// 	return data
		// }));
	}
	getAll() {
		const headers = new HttpHeaders({
			'Cache-Control': 'no-cache, no-store, must-revalidate'
		})
		return this.http.get('/api/notification/all', {headers: headers})
	}

	getActiveNotifications() {
		const headers = new HttpHeaders({
			'Cache-Control': 'no-cache, no-store, must-revalidate'
		})
		return this.http.get('/api/notification/active', {headers: headers})
	}

	getById(notificationId) {
		const headers = new HttpHeaders({
			'Cache-Control': 'no-cache, no-store, must-revalidate'
		})
		return this.http.get('/api/notification/'+notificationId, {headers: headers})
	}

	createOrUpdate(notification: NotificationModel): Observable<any> {
		let api = `/api/notification`;
		if (notification._id) {
			return this.http.put(api, {notification})
		} else {
			return this.http.post(api, {notification})
		}

	}
}
