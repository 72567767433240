import {Injectable} from '@angular/core';
import {HelpersService} from '../helpers.service';
import {Observable, BehaviorSubject, throwError, Subject} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {EpisodeModel} from './episode.model';

@Injectable({
	providedIn: 'root'
})
export class EpisodeService {

	constructor(
		private http: HttpClient,
		private helpersService: HelpersService,
		public router: Router,
	) {
	}

	create(episode: EpisodeModel): Observable<any> {
		let api = `/api/episode`;
		episode.fromDate = new Date(new Date(episode.fromDate).toISOString())
		episode.toDate = new Date(new Date(episode.toDate).toISOString())
		return this.http.post(api, {episode})
	}

	update(episode: EpisodeModel): Observable<any> {
		let api = `/api/episode`;
		episode.fromDate = new Date(new Date(episode.fromDate).toISOString())
		episode.toDate = new Date(new Date(episode.toDate).toISOString())
		return this.http.put(api, {episode})
	}

	getAll(): Observable<any> {
		let api = `/api/episode/all`;
		return this.http.get(api)
			.pipe(
				catchError(this.helpersService.handleError)
			)
	}

	getAllForEvent(eventId): Observable<any> {
		let api = `/api/episode/${eventId}`;
		return this.http.get(api)
			.pipe(
				catchError(this.helpersService.handleError)
			)
	}

  closeEpisode(episode): Observable<any> {
    let api = `/api/episode/close`;
    return this.http.put(api, {episode})
      .pipe(
        catchError(this.helpersService.handleError)
      )
  }

}
