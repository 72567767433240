<div class="modal-header">
	<h4 class="modal-title" id="modal-basic-title">{{this.lngService.getFieldTranslate(achievement, 'name')}}</h4>
	<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">
	<div class="achievement-container container-fluid p-0">
		<div class="row">
			<div class="achievement-container__images col-12 col-sm-4 text-center mb-3">
				<img src="{{achievement.img}}">
				<div class="pb-2"></div>
				<img src="{{achievement.short_img}}">
			</div>
			<div class="col-12 col-sm-8 text-left">
				<p>{{this.lngService.getFieldTranslate(achievement, 'description')}}</p>
			</div>
		</div>
	</div>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-primary" (click)="activeModal.close('yes')">ok</button>
</div>
