import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {environment} from "../../../environments/environment";

@Component({
	selector: 'app-modal-report-result',
	templateUrl: './modal-report-result.component.html',
	styleUrls: ['./modal-report-result.component.css']
})
export class ModalReportResultComponent implements OnInit {
	specopsStatus = environment.specopsStatus;
	@Input() report;

	constructor(public activeModal: NgbActiveModal
	) {
	}

	ngOnInit(): void {
	}

}
