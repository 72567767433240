import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AchievementModel} from "../../_helpers/achievement/achievement.model";
import {LngService} from "../../_helpers/lng/lng.service";

@Component({
	selector: 'app-modal-achievement',
	templateUrl: './modal-achievement.component.html',
	styleUrls: ['./modal-achievement.component.scss']
})
export class ModalAchievementComponent implements OnInit {
	@Input() achievement;

	constructor(
		public activeModal: NgbActiveModal,
		public lngService: LngService
	) {}

	ngOnInit(): void {
		console.log('this.achievement', this.achievement)
	}

}
