import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'searchFilter' })
export class SearchFilterPipe implements PipeTransform {

  transform(list: any[], filterText: string): any {
    if (!list || !filterText) {
      return list;
    }
    return list ? list.filter(item => {
		console.log('item.name', item.name)
		return item.name.search(new RegExp(filterText, 'i')) > -1
	}) : [];
  }
}
