import {Injectable} from '@angular/core';
import {Page} from "./page";
import {environment} from "../../../environments/environment";

@Injectable()
export class PaginationService {
	page = new Page();
	constructor() {
	}

	makeQuery(config) {
		let request ='?';
		if(config) {
			request += `pageNumber=${config.currentPage-1}&pageSize=${config.itemsPerPage}&`
			if(config.sort) {
				request += `sort=${JSON.stringify(config.sort)}&`
			}
			if(config.query) {
				request += `query=${JSON.stringify(config.query)}`
			}
		}

		return request;
	}
}
