import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
	selector: 'app-modal-error',
	templateUrl: './modal-error.component.html',
	styleUrls: ['./modal-error.component.css']
})
export class ModalErrorComponent implements OnInit {

	constructor() {}

	ngOnInit(): void {
	}

}
