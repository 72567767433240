<div *ngIf="notification" class="toast show {{notification.type}}" role="alert" aria-live="assertive" aria-atomic="true">
	<div class="toast-header">
		<img src="https://orbit.live-webagentur.eu/assets/media/logo.png" class="rounded mr-2" alt="...">
		<strong class="mr-auto">{{this.lngService.getFieldTranslate(notification, 'title')}}</strong>
		<small class="text-muted">{{notification.created_at | date: 'medium'}}</small>
		<button type="button" class="ml-2 mb-1 close" (click)="closeNotification(notification)" data-bs-dismiss="toast" aria-label="Close">
			<span aria-hidden="true">×</span>
		</button>
	</div>
	<div class="toast-body">
		{{this.lngService.getFieldTranslate(notification, 'text')}}
	</div>
</div>
