import {Component, Input, OnInit} from '@angular/core';
import {
	NgbActiveModal,
	NgbCalendar,
	NgbDate,
	NgbDateParserFormatter,
	NgbDateStruct,
	NgbModal
} from '@ng-bootstrap/ng-bootstrap';
import * as moment from "moment";
import {EpisodeModel} from "../../../_helpers/episode/episode.model";
import {EpisodeService} from "../../../_helpers/episode/episode.service";
import {environment} from "../../../../environments/environment";
import {EpisodeTextInfoModel} from "../../../_helpers/episode/episodeTextInfo.model";

@Component({
	selector: 'app-edit-episode',
	templateUrl: './edit-episode.component.html',
	styleUrls: ['./edit-episode.component.scss']
})
export class EditEpisodeComponent implements OnInit {
	@Input() newEpisode;
	@Input() eventId;
	@Input() lngs;
	@Input() currentLng;

	hoveredDate: NgbDate | null = null;
	fromDate: NgbDate | null;
	toDate: NgbDate | null;
	fileLinkRegexp;
	errorMessage;

	defaultLng = environment.defaultLocale;
	alreadyAddedLngs = [];
	selectedLng;
	constructor(
		public activeModal: NgbActiveModal,
		private calendar: NgbCalendar,
		public formatter: NgbDateParserFormatter,
		private episodeService: EpisodeService
	) {
		this.fileLinkRegexp = 'https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,4}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)'
	}

	ngOnInit() {
		if(this.currentLng) {
			this.defaultLng = this.currentLng;
		}
		console.log('this.currentLng', this.currentLng)
		if (this.newEpisode && this.newEpisode._id) {
			let from = moment(this.newEpisode.fromDate);
			let to = moment(this.newEpisode.toDate);
			this.fromDate = NgbDate.from({year: from.year(), month: from.month() + 1, day: from.date()});
			this.toDate = NgbDate.from({year: to.year(), month: to.month() + 1, day: to.date()});
			this.lngs.forEach(elem => {
				if(this.newEpisode[elem.lng]) {
					this.alreadyAddedLngs.push(elem.lng)
				}
			})
			if(!this.newEpisode[this.defaultLng]) {
				this.alreadyAddedLngs.push(this.defaultLng);
				this.newEpisode[this.defaultLng] = new EpisodeTextInfoModel();
			}
		} else {
			this.newEpisode = new EpisodeModel();
			this.fromDate = this.calendar.getToday();
			this.toDate = this.calendar.getNext(this.calendar.getToday(), 'd', 10);
			this.newEpisode[this.defaultLng] = new EpisodeTextInfoModel();
			this.alreadyAddedLngs.push(this.defaultLng);
		}
	}

	onDateSelection(date: NgbDate) {
		if (!this.fromDate && !this.toDate) {
			this.fromDate = date;
		} else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
			this.toDate = date;
		} else {
			this.toDate = null;
			this.fromDate = date;
		}
	}

	isHovered(date: NgbDate) {
		return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
	}

	isInside(date: NgbDate) {
		return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
	}

	isRange(date: NgbDate) {
		return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
	}

	validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
		const parsed = this.formatter.parse(input);
		return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
	}

	closeAlert() {
		this.errorMessage = '';
	}

	saveNewEpisode() {
		this.newEpisode.fromDate = new Date(`${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day} 00:01`).toISOString();
		this.newEpisode.toDate = new Date(`${this.toDate.year}-${this.toDate.month}-${this.toDate.day} 23:59`).toISOString();
		const isURLValid = this.newEpisode[this.defaultLng].fileLink.match(this.fileLinkRegexp)
		if (!this.newEpisode.fromDate || !this.newEpisode.toDate || !this.newEpisode[this.defaultLng].name || !this.newEpisode[this.defaultLng].fileLink || !isURLValid) {
			return;
		}
		this.newEpisode.event = this.eventId;
		if (this.newEpisode._id) {
			this.episodeService.update(this.newEpisode).subscribe(
				res => {
					this.activeModal.close(res);
				},
				err => {
					this.errorMessage = err.message;
				}
			)
		} else {
			this.episodeService.create(this.newEpisode).subscribe(
				res => {
					this.activeModal.close(res);
				},
				err => {
					this.errorMessage = err.message;
				}
			)
		}
	}

	addTranslate() {
		this.alreadyAddedLngs.push('');
	}

	deleteTranslate(lng) {
		delete this.newEpisode[lng];
		this.alreadyAddedLngs = this.alreadyAddedLngs.filter((value, index, arr) => {
			return value !== lng;
		});
	}

	changeLng(nevLng, lng) {
		this.alreadyAddedLngs[this.alreadyAddedLngs.map((x, i) => [i, x]).filter(x => x[1] == lng)[0][0]] = nevLng
		if(!this.newEpisode[nevLng]) {
			this.newEpisode[nevLng] = new EpisodeTextInfoModel();
		}
		if(this.newEpisode[lng]) {
			delete this.newEpisode[lng]
		}
	}
}
