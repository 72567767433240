import {Injectable} from '@angular/core';
import {HelpersService} from '../helpers.service';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {EventService} from "../event/event.service";

@Injectable({
	providedIn: 'root'
})
export class FileService {

	constructor(
		private http: HttpClient,
		private helpersService: HelpersService,
		public router: Router,
		private eventService: EventService
	) {}

	uploadImage(fileToUpload, outputWidth, prevImageURL?, imageBucket?, folder?): any {
		const uploadData: FormData = new FormData();
		uploadData.append('file', fileToUpload, fileToUpload.name);
		uploadData.append('prevImageURL', prevImageURL);
		uploadData.append('outputWidth', outputWidth);
		uploadData.append('folder', folder);
		uploadData.append('imageBucket', imageBucket);
		return new Promise((resolve, reject) => {
				return this.http.post(`/api/file/upload`, uploadData).toPromise()
					.then((result: any) => {
						console.log('result', result)
						resolve(result.imageUrl); // RETURN IMAGE URL from response
					})
					.catch(error => {
						reject('Upload failed');
						// Handle error control
						console.error('Error:', error);
					});
			}
		);
	}

	base64ToFile(data, filename) {

		const arr = data.split(',');
		const mime = arr[0].match(/:(.*?);/)[1];
		const bstr = atob(arr[1]);
		let n = bstr.length;
		let u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}

		return new File([u8arr], filename, {type: mime});
	}

}
