import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-modal-info',
	templateUrl: './modal-info.component.html',
	styleUrls: ['./modal-info.component.css']
})
export class ModalInfoComponent implements OnInit {
	@Input() message;
	@Input() title;

	constructor(public activeModal: NgbActiveModal
	) {
	}

	ngOnInit(): void {
	}

}
