export class UserModel {
	constructor(data?) {
		let user;
		if (data && data.user) {
			user = data.user
		}
		if (data && data.nickname) {
			user = data
		}
		if (user && user.nickname) {
			this._id = user._id;
			this.nickname = user.nickname;
			this.itsPIN = user.itsPIN;
			this.faction = user.faction;
			this.avatar = user.avatar;
			this.rank = user.rank;
			this.isActive = user.isActive;
			this.achievements = user.achievements;
			this.isCurrent = user.isCurrent;
			this.role = user.role;
			this.rating = user.rating;
			this.army = user.army;
			this.objectivePoints = user.objectivePoints;
			this.numberWins = user.numberWins;
			this.numberLoses = user.numberLoses;
			this.numberDraws = user.numberDraws;
		} else {
			this.nickname = '';
			this.itsPIN = '';
			this.faction = undefined;
			this.army = undefined;
			this.avatar = '';
			this.achievements = [];
			this.isCurrent = false;
		}
	}

	public _id;
	public nickname;
	public itsPIN;
	public faction;
	public achievements;
	public avatar;
	public isActive;
	public isCurrent;
	public role;
	public army;
	public numberWins;
	public numberLoses;
	public numberDraws;
	public rank;
	public objectivePoints;
	public rating;
	public nicknameKey;
	public event;
}
