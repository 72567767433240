<div class="modal-header">
	<h4 class="modal-title" id="modal-basic-title">{{title}}</h4>
	<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">
	<form>
		<div class="form-group">
			{{message}}
		</div>
	</form>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-success" (click)="activeModal.close('yes')">{{ 'COMMON.YES' | translate }}</button>
	<button type="button" class="btn btn-danger" (click)="activeModal.close('no')">{{ 'COMMON.NO' | translate }}</button>
</div>
