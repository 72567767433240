import {BrowserModule} from '@angular/platform-browser';
import {NgModule, APP_INITIALIZER} from '@angular/core';
import {RouterModule} from '@angular/router';
import {HTTP_INTERCEPTORS} from '@angular/common/http';

import {AppComponent} from './app.component';
import {appRoutingModule} from './app.routing';

import {FactionService} from './_helpers/faction/faction.service';
import {UserService} from './_helpers/user/user.service';
import {AuthService} from './_helpers/auth/auth.service';
import {HelpersService} from './_helpers/helpers.service';
import {PaginationService} from './_helpers/pagination/pagination.service';
import {ErrorInterceptor} from './_helpers/error.interceptor';
import { ShareModule } from "./share/share.module";

export function init_app(authService: AuthService) {
	return () => authService.checkServerSession();
}

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		ShareModule,
		BrowserModule,
		appRoutingModule
	],
	exports: [RouterModule],
	providers: [
		{provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
		FactionService,
		UserService,
		HelpersService,
		PaginationService,
		AuthService,
		{provide: APP_INITIALIZER, useFactory: init_app, deps: [AuthService], multi: true}
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
