import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from "@angular/router";
import {BehaviorSubject, Observable} from "rxjs";
import {UserModel} from "./user.model";
import {GlobalUserModel} from "../globalUser/globalUser.model";
import {PaginationService} from "../pagination/pagination.service";

@Injectable(
	{
		providedIn: 'root'
	}
)
export class UserService {
	private currentUserObjectSubject: BehaviorSubject<UserModel>;
	constructor(
		public router: Router,
		private http: HttpClient,
		private paginationService: PaginationService
	) {
		this.currentUserObjectSubject = new BehaviorSubject(new UserModel());
	};


	// user
	public get currentUserObjectValue(): UserModel {
		return this.currentUserObjectSubject.value;
	}

	public setCurrentUserObjectValue(user) {
		this.currentUserObjectSubject.next(user);
	}

	public currentUserValueObjectSubscribe(): Observable<UserModel> {
		return this.currentUserObjectSubject;
	}

	public saveUserInfo(data) {
		console.warn('save user', data);
		const nickname = data ? data.nickname : '';
		const user = new UserModel(data)
		this.setCurrentUserObjectValue(user);
		localStorage.setItem('currentUser', JSON.stringify(nickname));
	}

	public createUser(user, needCreateSpecops): any {
		const headers = new HttpHeaders({
			'Cache-Control': 'no-cache, no-store, must-revalidate'
		})
		let api = `/api/user`;
		return this.http.post(api, {user, needCreateSpecops}, {headers})

	}

	public updateUser(user, needCreateSpecops): any {
		const headers = new HttpHeaders({
			'Cache-Control': 'no-cache, no-store, must-revalidate'
		})
		let api = `/api/user`;
		return this.http.put(api, {user, needCreateSpecops}, {headers})
	}

	public updateAvatar(user): any {
		const headers = new HttpHeaders({
			'Cache-Control': 'no-cache, no-store, must-revalidate'
		})
		let api = `/api/user/avatar`;
		return this.http.put(api, {user}, {headers})
	}

	public getByEventAndNickname(event, nickname, isNew): any {
		let api = `/api/user/${event}/${nickname}/${isNew}`;
		return this.http.get(api)
	}
	public getCurrentByEvent(event): any {
		let api = `/api/user/${event}`;
		return this.http.get(api)
	}

	public getAll(): any {
		let api = `/api/user/all`;
		return this.http.get(api)
	}

	public getRating(event, config?): any {
		let request = '';
		if(config) {
			request = this.paginationService.makeQuery(config);
		}
		let api = `/api/user/rating/${event}${request}`;
		return this.http.get(api)
	}

	public getAllWithOutLogged(event): any {
		let api = `/api/user/allWithOutLogged/${event}`;
		return this.http.get(api)
	}

	public getUsersOnPage(eventId, config?): any {
		let request = this.paginationService.makeQuery(config);
		let api = `/api/user/onPage/${eventId}${request}`

		return this.http.get(api)
	}
}
