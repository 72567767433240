<div class="modal-header">
	<h4 class="modal-title" id="modal-basic-title">{{ 'crop.title' | translate }}</h4>
	<button type="button" class="close" aria-label="Close" (click)="activeModal.close(false)">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">
	<form>
		<div class="form-group">
			<label>{{ 'crop.preview' | translate }}</label>
			<br/>
			<input class="btn btn-primary" type="file" data-max-size="2048" (change)="fileChangeEvent($event)"/>
			<image-cropper
					[imageChangedEvent]="imageChangedEvent"
					format="png, jpeg"
					(imageCropped)="imageCropped($event)"
					[aspectRatio]="aspectRatio"
					[resizeToWidth]="resizeToWidth"
					[maintainAspectRatio]="maintainAspectRatio"
			></image-cropper>
			<img class="image-cropped" [width]="resizeToWidth" [src]="croppedImage"/>
		</div>
	</form>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-success" (click)="uploadImage()">Да</button>
	<button type="button" class="btn btn-danger" (click)="activeModal.close(false)">Нет</button>
</div>


