import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NotificationService} from "../../_helpers/notification/notification.service";
import {NotificationModel} from "../../_helpers/notification/notification.model";
import {LngService} from "../../_helpers/lng/lng.service";

@Component({
	selector: 'app-notification',
	templateUrl: './notification.component.html',
	styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
	@Input() notification: NotificationModel;

	@Output() closeNotificationEvent = new EventEmitter<string>();
	constructor(
		public notificationService: NotificationService,
		public lngService: LngService
	) {	}

	ngOnInit(): void {

	}

	closeNotification(notifi) {
		this.closeNotificationEvent.emit(notifi._id);
	}
}
