<div class="d-flex">
	<a *ngFor="let language of languages" (click)="changeLocale(language.id)"
	   class="header-btn header-btn_left mr-0 lang-id_{{language.id}}"
	   [ngClass]="{'lang__active': language.id === selectedLanguage}">
		<span class="header-btn__label">
			{{ language.title }}
		</span>
		<span class="header-btn__bottom"></span>
	</a>
</div>
