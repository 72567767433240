<div class="modal-header">
	<h4 class="modal-title" id="modal-basic-title">Report result</h4>
	<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">
	<form>
		<div class="form-group">
			<h1 *ngIf="report.pointAuthor.scoreOP === report.pointOpponent.scoreOP">Ничья.</h1>
			<h1 *ngIf="report.pointAuthor.scoreOP > report.pointOpponent.scoreOP">Выиграл {{report.author.nickname}}</h1>
			<h1 *ngIf="report.pointAuthor.scoreOP < report.pointOpponent.scoreOP">Выиграл {{report.opponent.nickname}}</h1>
			<p>Objective point (ваши\противника): {{report.pointAuthor.scoreOP}}\{{report.pointOpponent.scoreOP}}</p>
			<p>Victory point (выжившие автора\противника): {{report.pointAuthor.VPLive}}\{{report.pointOpponent.VPLive}}</p>
			<p>Victory point (умершие автора\противника): {{report.pointAuthor.VPDie}}\{{report.pointOpponent.VPDie}}</p>
			<p>Жив ли spec-ops автора: {{ 'createreport.'+specopsStatus[report.pointAuthor.isSpecSurvive] | translate }}</p>
			<p>Жив ли spec-ops противника: {{ 'createreport.'+specopsStatus[report.pointOpponent.isSpecSurvive] | translate }}</p>
			<p>Уровень варкора автора: {{report.pointAuthor.varcorLevel}}</p>
			<p>Уровень варкора противника: {{report.pointOpponent.varcorLevel}}</p>
			<p class="mb-0">Количество багажников автора: {{report.numberOfSurvivors}}</p>
		</div>
	</form>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-primary" (click)="activeModal.close('yes')">OK</button>
</div>


