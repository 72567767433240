import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {FileService} from "../../_helpers/file/file.service";
import {ImageCroppedEvent, base64ToFile} from "ngx-image-cropper";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
	selector: 'app-modal-photo-crop',
	templateUrl: './modal-photo-crop.component.html',
	styleUrls: ['./modal-photo-crop.component.scss']
})
export class ModalPhotoCropComponent implements OnInit {
	@Input() aspectRatio;
	@Input() maintainAspectRatio;
	@Input() prevImageURL;
	@Input() resizeToWidth;
	@Input() imageBucket;
	@Input() folder;
	// aspectRatio;
	imageChangedEvent: any = '';
	croppedImage: any = '';
	fileForUpload: any;
	imagename: any;

	constructor(
		private fileService: FileService,
		public activeModal: NgbActiveModal
	) {}

	ngOnInit(): void {
		console.log('this.aspectRatio', this.aspectRatio);
		if(!this.aspectRatio) {
			this.aspectRatio = 1/1;
		}
		if(!this.resizeToWidth) {
			this.resizeToWidth = 0;
		}
		if(this.maintainAspectRatio === undefined) {
			this.maintainAspectRatio = true;
		}
	}


	fileChangeEvent(event: any): void {
		console.log('event', event);
		this.imageChangedEvent = event;
		this.imagename = event.target.files[0];
		console.log('event.target.files', event.target.files);
	}

	imageCropped(event: ImageCroppedEvent) {
		this.croppedImage = event.base64;
		const imageBlob = this.dataURItoBlob(this.croppedImage);
		this.fileForUpload = new File([imageBlob], this.imagename.name, {type: 'image/jpeg'});
	}

	dataURItoBlob(dataURI) {
		const binary = atob(dataURI.split(',')[1]);
		const array = [];
		for (let i = 0; i < binary.length; i++) {
			array.push(binary.charCodeAt(i));
		}
		return new Blob(
			[new Uint8Array(array)],
			{
				type: 'image/png',
			},
		);
	}

	uploadImage() {
		console.log('this.fileForUpload', this.fileForUpload)
		let fileURL = this.fileService.uploadImage(this.fileForUpload, this.resizeToWidth, this.prevImageURL, this.imageBucket, this.folder);
		this.activeModal.close(fileURL);
	}

}
